import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { ProductPageContainerState } from 'SourceRoute/ProductPage/ProductPage.type';
import { ReactElement } from 'SourceType/Common.type';
import { RootState } from 'SourceUtil/Store/Store.type';

import ProductPage from './ProductPage.component';
/** @namespace Inov8/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Inov8/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Inov8/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    containerFunctions = {
        getLink: this.getLink.bind(this),
        setActiveProduct: this.setActiveProduct.bind(this),
        isProductInformationTabEmpty: this.isProductInformationTabEmpty.bind(this),
        isProductAttributesTabEmpty: this.isProductAttributesTabEmpty.bind(this),
        setParameters: this.setParameters.bind(this),
        getParameters: this.getParameters.bind(this),
    };

    state: ProductPageContainerState = {
        parameters: {},
        currentProductSKU: '',
        activeProduct: null,
        selectedParams: {},
    };

    setParameters(param: {}): void {
        this.setState({ selectedParams: param });
    }

    getParameters(): Record<string, string | string[]> {
        if (this.state.selectedParams === undefined) {
            return {};
        }

        return this.state.selectedParams;
    }

    updateMeta(): void {
        const { updateMetaFromProduct } = this.props;
        const {
            name = '',
            meta_title = '',
            meta_keyword = '',
            meta_description = '',
        } = this.getDataSource();

        updateMetaFromProduct({
            name,
            meta_title,
            meta_keyword,
            meta_description,
        });
    }

    // TODO implement logic
    render(): ReactElement {
        return (
            <ProductPage
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
